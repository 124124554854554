$lightGreen: #00df89;
$milkGreen: #7ed7a0;
$violet: #6658dd;
$milkBlue: #7392d0e8;
$darkBlue: #4d78cd;
$gray: #d0cccc;
$bcgGray: #f5f6f8;
$mainAreaHeight: calc(100vh - 100px);
$onboardingHeight: calc(100vh - 100px - 16vh);


.react-datepicker__aria-live {
    display: none;
}

.date-modal {
    text-align: center;
}

.poa-not-valid {
    color: #de4437;
    font-weight: bold;
}
.profile-wrapper {

    .btn-primary {
        color: $darkBlue;
        background-color: white;
        border-color: $darkBlue;
    }

    .btn-primary:disabled {
        background-color: $darkBlue;
        opacity: 0.25;
    }

    .btn-primary:hover {
        color: #fff;
        background-color: $darkBlue;
        border-color: $darkBlue;
    }

    .btn-primary:focus {
        color: #fff;
        background-color: $darkBlue;
        border-color: $darkBlue;
    }

    .btn-primary.revert {
        color: #fff;
        background-color: $darkBlue;
        border-color: $darkBlue;
    }

    .btn-primary.revert:hover {
        color: $darkBlue;
        background-color: #fff;
        border-color: $darkBlue;
    }

    .btn-primary.revert:focus {
        color: #fff;
        background-color: $darkBlue;
        border-color: $darkBlue;
    }

    padding: 30px 30px 30px 0px;
    width: calc(100% + 30px);
    display: grid;
    grid-template-areas: 'left';
    grid-template-columns: 98%;    
    height: $mainAreaHeight;
    overflow: auto;

    .card-shadow {
        box-shadow: 0px 0px 12px 1px #8080801f;
    }

    .no-data {
        width: 100%;
        text-align: center;
        margin-top: 70px;
        font-size: 20px;
        color: #bbb;
    }
    
    .off-canvas-trigger {
        position: absolute;
        right: -52px;
        z-index: 5;
        top: 130px;
        width: 152px;
        // padding-bottom: 50px;
        transform: rotate(-90deg);
        font-size: 1.1rem;
        font-weight: 700;
        text-align: center;
        // background-color: $violet;
        color: #fff;
        border: 2px solid #fff;
        // outline: 1px solid green;
        // box-shadow: -8px 0px 16px 4px #80808091;
        box-shadow: -5px -5px 19px 0px #808080db;
        transition: 0.5s all ease;
        
        
        .fe-chevron-up:before {
            margin-bottom: -5px;
            display: block;
        }
    }
    
    .off-canvas-trigger.comments-button {
        top: 290px;
        overflow: hidden;
    }

    .off-canvas-trigger.tasks-button {
        top: 450px;
        overflow: hidden;
    }
    
    .off-canvas-trigger:hover {
        border: 2px solid #fff;
        scale: 1.1;
    }
    
    .off-canvas-trigger:focus {
        box-shadow: -5px -5px 19px 0px #808080db;
        border: 2px solid #fff;
    }

    .expand-client-data {
        background-color: #fff;
        /* border-bottom: 2px solid #7392d0e8; */
        padding: 20px;
        /* height: 150px; */
        display: flex;
        font-size: 0.9rem;
        flex-wrap: wrap;
        align-content: flex-start;

        p {
            min-width: 250px;
            margin-left: 20px;
        }
    }

    .client-info-wrapper {
        grid-area: clientInfo;
        background-color: #fff;
        margin-bottom: 25px;

        .info-item.buttons-item {
            width: 18%;
            display: inline-flex;
            justify-content: space-between;
            vertical-align: top;
            margin-top: 15px;
            margin-right: 2%;
            button.edit-button {

                border: none;
                margin-right: 10px;
            }

            button i {
                //font-size: 1.3rem;
                margin: 0 5px;
                //position: relative;
                //top: 2px;
            }

            .expand-btn {
                float: right;
                margin-right: 10px;
                margin-top: 4px;
                background: none;
                display: inline-block;
                border: none;
                color: #4d78cd;
                cursor: pointer;
                font-size: 20px;

            }
        }

        .client-info-header {
            width: 80%;
            display: inline-block;
            padding: 10px 10px 10px 20px;

            .items {
                display: grid;
                row-gap: 25px;
                grid-template-columns: repeat(6, calc(100% / 6)) [col-start];
                
                .edit-profile-icon {
                    font-size: 1.4rem;
                    cursor: pointer;
                }

                .info-item {
                    .data {
                        font-weight: 700;
                        margin-left: 2px;
                        color: $darkBlue;
                    }
                }
            }

        }
    }

    .regular-modules {
        display: block;
        min-width: 800px;
        transition: 0.5s all ease;

        .module-tabs-wrapper {
            margin-bottom: 25px;
            grid-area: tabs;
            display: flex;
            align-items: center;
            // margin-left: 5px;

            button {
                width: 100%;
                display: block;
                height: 90%;
                font-size: 1.1rem;
                text-transform: capitalize;
            }

            button.selected, .btn-primary.active.selected {
                background-color: $darkBlue;
                border-color: $darkBlue;
                color: #fff;
                box-shadow: 0 0 0 0.15rem #7d71e280;
            }

            .btn-primary.active {
                color: $darkBlue;
                background-color: white;
                border-color: $darkBlue;
            }

            .btn-primary.active {
                // background-color: $milkGreen;
                // border-color: $milkGreen;
               i {
                margin-left: 10px;
                color: $milkGreen;
                font-weight: 700;
                font-size: 1.1rem;
               }
            }

            .btn-primary.active:hover {
                background-color: $darkBlue;
                border-color: $darkBlue;
                color: #fff;
            }

            button:not(:last-child) {
                margin-right: 5px;
            }
        }
        
        .timeline-row-wrapper {
            transition: 0.5s all ease;
            grid-area: timeline-row;
            display: grid;
            // grid-template-columns: 35% 60%; 
            // grid-template-columns:  minmax(350px, 35%) minmax(350px, 65%);   
            // gap: 25px;   
            background-color: var(--bs-body-bg);

            .module-state.card {
                height: 100%;
                margin: 0;
                padding: 10px 0 0 0;
                display: flex;
                justify-content: space-evenly;
                align-items: center;

                .pension-status-wrapper {
                    width: 80%;
                    min-width: 320px;
                    margin-top: -10px;
                }
                
                .status-widget {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 80%;
                    min-width: 320px;
                    margin: 0px auto 0px auto;
                    background: rgb(208,204,204);
                    background: linear-gradient(270deg, rgba(208,204,204,1) 0%, rgba(208,204,204,1) 46%, $milkBlue 51%, $milkBlue 100%);
                    border-radius: 15px 3px;
                    
                    .percent {
                        position: absolute;
                        top: 30%;
                        z-index: 500;
                        color: #00df89;
                    }

                    .progress-wrapper {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        margin: -5px auto;
                        width: 60%;
                        min-height: 65px;
                        border-radius: 30px 3px;
                        box-shadow: 0px 1px 17px -3px #000000b3;
                        z-index: 200;

                        .title {
                            position: absolute;
                            top: -50%;
                            color: $lightGreen;
                            font-weight: 700;
                            background-color: #fff;
                            z-index: 100;
                            display: block;
                            width: 90px;
                            padding: 2px;
                            text-align: center;

                        }
                        
                        .title-lines {
                            border: 1px solid black;
                            height: 30px;
                            width: 110px;
                            position: absolute;
                            top: -28%;
                            z-index: 1;
                        }
                    
                        .progress {
                            width: 100%;
                            min-height: 65px;
                            border-radius: 30px 3px;
                            z-index: 300;
                            
                            .progress-bar {
                                font-size: 1rem;
                                background-color: $milkGreen;
                            }
                        }
                    }
            
                    .prev {
                        width: 30%;
                        text-align: center;
                        color: white;
                    }
                    
                    .next {
                        width: 30%;
                        text-align: center;
                    }
                }
            
                .status-text {
                    font-size: 1rem;
                    text-align: center;
                    color: $darkBlue;
                    display: flex;
                    align-items: center;
                    justify-content: center;
            
                    strong {
                        margin-right: 5px;
                        font-size: 1.1rem;
                    }
            
                    span {
                        margin: 0 5px;
                    }
                }
            
                .dropdown{
                    width: 80%;
                    min-width: 320px;
                    margin-top: -10px;
            
                    .dropdown-menu.show {
                        width: 100%;
                        text-align: center;
                        font-weight: 600;
                    }
            
                    .dropdown-toggle {
                        width: 100%;
                        color: #fff;
                        background-color: $darkBlue;
                        border-color: $darkBlue;
                    }

                    .dropdown-toggle:focus {
                        box-shadow: none;
                    }
            
                    .dropdown-toggle:after {
                        display: inline-block;
                        margin-left: 0.7em;
                        vertical-align: 0.2em;
                        content: "";
                        border-top: 0.3em solid;
                        border-right: 0.3em solid transparent;
                        border-bottom: 0;
                        border-left: 0.3em solid transparent;
                    }
                }
            }
            
            .timeline-table {
                background-color: #fff;
                padding: 20px 30px;
                overflow: auto;
                margin-bottom: 25px;
                //min-height: 300px;

                .pointer {
                    min-width: 50px;
                    display: block;
                    transition: 0.3s all ease;
                }

                .time-line-header {
                    display: flex;
                    justify-content: space-between;

                    .ç {
                        margin-right: 10px;
                    }

                    .create-round.blocked {
                        opacity: 0.5;
                        cursor: not-allowed;
                    }

                    .create-round.blocked:hover {
                        opacity: 0.5;
                        color: #fff;
                        background-color: $darkBlue;
                        border-color: $darkBlue;
                    }
                }

                .title {
                    text-transform: capitalize;
                    color: #6e768e;
                    font-family: "Cerebri Sans,sans-serif";
                    font-size: 0.95rem;
                }

                .table-responsive {
                    overflow: unset;
                }

                .active-round {
                    font-weight: bold;
                    color: #44d29b;
                    cursor: pointer;

                    i {
                        font-weight: bolder;
                        margin-left: 5px;
                    }
                }

                .active-round.round {
                    font-weight: bold;
                    background-color: #44d29b;
                    color: #fff;
                    cursor: pointer;
                    padding: 3px 45px 3px 10px;
                    border-radius: 5px;

                    i {
                        font-weight: 500;
                        margin-left: 10px;
                    }
                }

                .pension-status {
                    max-width: 310px;
                }

                .progress-bar-wrapper {
                    width: 200px;

                    .progress {
                        display: flex;
                        height: 1.3rem;
                        font-size: 12px;
                    }
                }
            }
        }

        .timeline-row-wrapper.not-active-module {
            transition: 0.5s all ease;
            grid-template-columns:  100%;   
        }
        
        .main-data-wrapper {
            grid-area: mainData;
            background-color: #fff;
            position: relative;

           

            .select-round {
                background-color: #f2f3f6;
                position: absolute;
                right: 20px;
                top: 3px;


                
                .display {
                    color: $darkBlue;
                    cursor:default;
                    pointer-events: none;
                    border: 1px solid $darkBlue;
                    background-color: #fff;
                }
                
                .dropdown-toggle {
                    color: $darkBlue;
                    background-color: #fff;
                    border: 2px solid $darkBlue;
                }
                .dropdown-toggle:after {
                    display: inline-block;
                    margin-left: 0.7em;
                    vertical-align: 0.2em;
                    content: "";
                    border-top: 0.3em solid;
                    border-right: 0.3em solid transparent;
                    border-bottom: 0;
                    border-left: 0.3em solid transparent;
                }
            }

            .detailed-tab {
                padding: 30px;

                .select-area {
                    max-width: 500px;

                    .inputWrapper {
                        margin-bottom: 20px;
                    }
                }
            }
            
            .nav-tabs {
                //width: 60%;
                background-color: #f5f6f8;
                
                .nav-item {
                    .nav-link {
                        padding: 1rem 1.5rem 0.5rem 1.5rem;
                    }
                }
            }

            .tab-content {
                overflow: auto;
                height: 90%;

                .user-profile-box.card {
                    margin-bottom: 0;
                    box-shadow: none;

                    .buttons-row {
                        display: flex;
                        justify-content: flex-end;

                        button {
                            background-color: $darkBlue;
                            color: #fff;
                            height: 50px;

                            i {
                                font-size: 1.2rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .regular-modules.data-expanded {
        grid-template-rows: minmax(200px, 14vh) minmax(70px, 7vh) minmax(200px, 24vh) minmax(350px, 50vh);   
    }

    .onboarding-module {
        display: block;
        min-width: 800px;
        transition: 0.5s all ease;
        
        .module-tabs-wrapper {
            margin-bottom: 25px;
            grid-area: tabs;
            display: flex;
            align-items: center;
            margin-left: 5px;

            button {
                width: 100%;

                display: block;
                height: 90%;
                font-size: 1.1rem;
                text-transform: capitalize;
            }

            button.selected, .btn-primary.active.selected {
                background-color: $darkBlue;
                border-color: $darkBlue;
                color: #fff;
                box-shadow: 0 0 0 0.15rem #7d71e280;
            }

            .btn-primary.active {
                color: $darkBlue;
                background-color: white;
                border-color: $darkBlue;
            }

            .btn-primary.active {
                // background-color: $milkGreen;
                // border-color: $milkGreen;
               i {
                margin-left: 10px;
                color: $milkGreen;
                font-weight: 700;
                font-size: 1.1rem;
               }
            }

            .btn-primary.active:hover {
                background-color: $darkBlue;
                border-color: $darkBlue;
                color: #fff;
            }

            button:not(:last-child) {
                margin-right: 5px;
            }
        }


        
        .onboarding-layout {
            grid-area: onboarding;

            
            .module-state.card {
                height: 40%;
                margin: 0;
                padding: 10px 0 0 0;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
            
                .status-widget {
                    display: flex;
                    align-items: center;
                    position: relative;
                    width: 80%;
                    min-width: 260px;
                    margin: 0px auto 0px auto;
                    background: rgb(208,204,204);
                    border-radius: 30px 3px 15px 3px;

                    .progress-wrapper {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        margin: -5px 0 -5px -1px;
                        width: 60%;
                        min-height: 65px;
                        border-radius: 30px 3px;
                        box-shadow: 0px 1px 17px -3px #000000b3;
                        z-index: 200;

                        .title {
                            position: absolute;
                            top: -50%;
                            color: $lightGreen;
                            font-weight: 700;
                            background-color: #fff;
                            z-index: 100;
                            display: block;
                            width: 90px;
                            padding: 2px;
                            text-align: center;
                        }
                        
                        .title-lines {
                            border: 1px solid black;
                            height: 30px;
                            width: 110px;
                            position: absolute;
                            top: -28%;
                            z-index: 1;
                        }
                    
                        .progress {
                            width: 100%;
                            min-height: 65px;
                            border-radius: 30px 3px;
                            z-index: 300;
                            
                            .progress-bar {
                                font-size: 1rem;
                                background-color: $milkGreen;
                            }
                        }
                    }
            
                    .prev {
                        display: none;
                        width: 30%;
                        text-align: center;
                        color: white;
                    }
                    
                    .next {
                        width: 40%;
                        text-align: center;
                    }
                }
            
                .status-text {
                    font-size: 1rem;
                    text-align: center;
                    color: $darkBlue;
                    display: flex;
                    align-items: center;
                    justify-content: center;
            
                    strong {
                        margin-right: 5px;
                        font-size: 1.1rem;
                    }
            
                    span {
                        margin: 0 5px;
                    }
                }

                .pension-status-wrapper {
                    width: 80%;
                    min-width: 320px;
                    margin-top: -10px;
                }
            
                .dropdown{
                    width: 80%;
                    min-width: 320px;
                    margin-top: -10px;
            
                    .dropdown-menu.show {
                        width: 100%;
                        text-align: center;
                        font-weight: 600;
                    }
            
                    .dropdown-toggle {
                        width: 100%;
                        color: #fff;
                        background-color: $darkBlue;
                        border-color: $darkBlue;
                    }

                    .dropdown-toggle:focus {
                        box-shadow: none;
                    }
            
                    .dropdown-toggle:after {
                        display: inline-block;
                        margin-left: 0.7em;
                        vertical-align: 0.2em;
                        content: "";
                        border-top: 0.3em solid;
                        border-right: 0.3em solid transparent;
                        border-bottom: 0;
                        border-left: 0.3em solid transparent;
                    }
                }
            }

            .user-profile-box {
                // min-height: calc(100vh - 100px - 16vh - 40%);
                margin-bottom: 0;
            }

            .file-list.card {
                //min-height: $onboardingHeight;
                margin-bottom: 20px;
            }

            .chat-area.card {
                min-height: $onboardingHeight;
                margin-bottom: 0;


            }

            .whatsapp-window.includes-send-area {
                height: 350px !important;
            }
        }
    }

    .onboarding-module.data-expanded {
        //grid-template-rows: minmax(200px, 14vh) minmax(70px, 7vh) min-content;
    }

    .col-right {
        grid-area: right;
        height: 70vh;
        min-width: 350px;
        background-color: beige;
        display: grid;
        grid-template-areas:
        'chats'
        'comments';
        grid-template-columns: 100%;    
        // grid-template-rows: minmax(310px, 38vh) minmax(350px, 50vh);   
        row-gap: 8px;

        .chat-area {
            grid-area: chats;
            
        }
        
        .comments-box {
            grid-area: comments;

        }
    }
}


.profile-wrapper ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.profile-wrapper ::-webkit-scrollbar-thumb {
    background: $milkBlue;
    border-radius: 20px;
}

.profile-wrapper ::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.profile-wrapper {
    .simplebar-scrollbar:before {
        background: #1abc9c;
        opacity: 1;
    }
    .simplebar-scrollbar.simplebar-visible:before {
        opacity: 1;
    }
}

*, *::before, *::after {
    box-sizing: border-box;
}

body .offcanvas-end {
    width: 800px;
}

.task-count {
    font-size: 0.6rem;
    color: var(--bs-white);
    background-color: var(--bs-red);
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: inline-block;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
    padding: 0;
    margin-left: 5px;
    position: relative;
    bottom: 3px;
}

.comment-count {
    font-size: 0.6rem;
    color: var(--bs-white);
    background-color: #4d78cd;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: inline-block;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
    padding: 0;
    margin-left: 5px;
    position: relative;
    bottom: 3px;
}

.table-link {
    width: 200px;
}

.form-select {
    min-width: 100px;
}

.actions-and-filters {
    justify-content: space-between;

    .new-task {
        margin-top: 29px;
    }
}

.multiple-select {
    min-width: 250px;
    max-width: 600px;
    width: auto;
    .prefix {
        color: rgb(67,109,198) !important;
        border-color: rgb(67,109,198) !important;
    }
}

.vip-client {
    background-color: #deffff !important;
  }