.warning-banner {
    position: fixed;
    width: 50%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color:  var(--bs-red);
    color: #000;
    padding: 10px 20px;
    border-radius: 5px;
    opacity: 0.95;
    font-size: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 99999999; 
  }
  