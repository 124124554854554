.whatsapp-manager-wrapper {
    display: flex;
    border: 1px solid lightgray;
    padding: 5px;
    border-radius: 5px;
    height: calc(98vh - 90px);
    margin-top: 25px;
    overflow-x: auto;
    overflow-y: hidden;

    display: flex;
    border: 1px solid lightgray;
    padding: 5px;
    border-radius: 8px;
    height: calc(98vh - 90px);
    margin-top: 25px;
    overflow-x: auto;
    overflow-y: hidden;
    box-shadow: 0px -5px 12px 1px #80808073;

    .chat-list-wrapper {
        width: 25%;
        background-color: white;
        height: calc(98vh - 100px);
        overflow-y: clip;
        border-radius: 0.25rem;
        box-shadow: 3px 5rem 3rem #38414a17;
        position: relative;

        .chat-skeleton-container {
            display: flex;
            flex-direction: column;
            // padding: 10px;
            // gap: 10px;

            hr {
                width: 100%;
                margin: 10px 10px 0px 0;
            }
        }

        .chat-skeleton-item {
            display: flex;
            align-items: center;
            padding: 10px 10px 0px 10px;
            width: 400px;
            height: 69px;

            .avatar-placeholder {
                width: 40px;
                height: 40px;
                border-radius: 50%; // Makes the skeleton circle if using a div
            }

            .text-placeholder {
                margin-left: 10px;
                flex: 1;


                .first-line {
                    width: 60%;
                    margin-bottom: 5px; // Spacing between skeleton lines
                    height: 16px;
                }

                .second-line {
                    width: 30%;
                    height: 16px;
                }

            }
        }


        .chat-list {
            .infinite-scroll-component {
                height: calc(98vh - calc(100px + 160px)) !important;
                overflow: auto;
            }

            .pin {
                font-size: 20px;
                color: #ddd;
            }

            ;

            .pin.pinned {
                color: #16a085;
            }
        }

        .chat-list.is-manager {
            .infinite-scroll-component {
                height: calc(98vh - calc(100px + 246px)) !important;
                overflow: auto;
            }
        }

        .preloader {
            position: absolute;
        }

        .no-chats-found {
            text-align: center;
            margin-top: 50px;
            font-size: 15px;
        }

        .chat-list-actions {
            padding: 15px;
            border-bottom: 2px solid lightgray;

            .display-as {

                .join-msg {
                    margin-top: 10px;
                    background-color: #92b6ed;
                    width: 100%;
                    padding: 15px;
                    color: white;
                    text-align: center;
                    font-size: 1.2rem;
                }

                .title-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .title span {
                        font-size: 1em;
                        font-weight: 400;
                    }

                    .box_1 {
                        margin-bottom: 12px;
                        display: flex;
                        padding: 5px 8px;
                        // box-shadow: 1px 1px 4px #80808087;
                        margin-right: 1px;
                        // border: 1px solid #1abc9c;
                        min-width: 145px;
                        justify-content: flex-end;

                        .mode-title {
                            margin-right: 5px;
                            // color: #c005f2;
                            color: var(--bs-body-color);
                        }
                    }

                    input[type="checkbox"].switch_1 {
                        font-size: 15px;
                        border: 1px solid lightgrey;
                        appearance: none;
                        width: 3.5em;
                        height: 1.5em;
                        background: #eeeeee;
                        border-radius: 3em;
                        position: relative;
                        cursor: pointer;
                        outline: none;
                        transition: all .2s ease-in-out;
                    }

                    input[type="checkbox"].switch_1:checked {
                        background: #4a81d4;
                    }

                    input[type="checkbox"].switch_1:after {
                        position: absolute;
                        content: "";
                        width: 1.6em;
                        height: 1.6em;
                        border-radius: 50%;
                        background: #fff;
                        box-shadow: 0 0 0.25em #0000004d;
                        left: 0;
                        top: -2px;
                        transition: all .2s ease-in-out;
                    }

                    input[type="checkbox"].switch_1:checked:after {
                        left: calc(100% - 1.5em);
                    }

                }
            }

            .display-as.team-member {
                .title span {
                    background-color: #fb9fa887;
                    color: #000;
                    padding: 5px 10px;
                    border-radius: 5px;
                    display: block;
                    font-size: 12px;
                }
            }

            .agent-display-wrapper {
                position: relative;

                .join-mode-cover {
                    background-color: #92b6ed;
                    color: #fff;
                    font-size: 1.4rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    height: 123px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 100%;
                    z-index: 99999;
                    padding: 0 30px;
                    text-align: center;

                    .mdi {
                        position: absolute;
                        bottom: 10%;
                        right: 5%;
                        transform: rotate(345deg);
                        animation: arrow 1s ease 0s 2 alternate;
                    }

                    @keyframes arrow {
                        from {
                            bottom: 10%;
                        }

                        to {
                            bottom: 5%;
                        }
                    }
                }

                .filter-wrapper {
                    margin-top: 10px;
                    display: block;

                    select {
                        text-transform: capitalize;
                    }

                    .filter-message {
                        grid-area: msg;
                    }

                    .filter-status {
                        grid-area: stt;
                    }

                    .filter-btn {
                        grid-area: btn;
                        padding: unset;
                        background-color: #1abc9c;
                        border-color: #1abc9c;
                        transition: 0.5s ease;
                    }

                    .filter-btn:hover {
                        background-color: #14806b;
                        border-color: #14806b;
                    }


                }

                .search-wrapper {
                    margin-top: 10px;
                    display: grid;
                    grid-template-columns: 100%;

                    .search-input {
                        margin-top: 0;

                        .form-control {
                            padding-left: 20px;
                            padding-right: 20px;
                            border-radius: 0.25rem;
                        }

                        .apply-search {
                            position: absolute;
                            z-index: 9;
                            font-size: 16px;
                            line-height: calc(1.5em + 0.68rem);
                            right: 0px;
                            left: unset;
                            top: 0px;
                            color: grey;
                            border: 1px solid lightgrey;
                            padding: 0 15px;
                            background-color: #eeeeee;
                            cursor: pointer;
                            transition: 0.5s ease;
                            width: 22%;
                            text-align: center;
                            border-radius: 0.25rem;
                        }

                        .apply-search:hover {
                            background-color: #c1c1c1;
                            color: #ffff;
                            border-color: #c1c1c1;
                        }
                    }
                }
            }
        }

        .chat-list {
            // overflow-y: auto;
            // overflow-x: clip;
            // height: calc(95vh - calc(145px + 150px));
            list-style-type: none;
            margin: 0;
            margin-block-start: 0;
            margin-block-end: 0;
            padding-inline-start: 0;

            .chat-item {
                position: relative;
                border-bottom: 1px solid lightgray;
                padding: 10px;
                display: flex;
                height: 80px;

                .avatar {
                    display: flex;
                    width: 15%;
                    align-items: center;
                    position: relative;

                    .rep {
                        background: #1abc9c;
                        height: 42px;
                        width: 42px;
                        color: white;
                        border-radius: 50%;
                        line-height: 42px;
                        display: block;
                        font-weight: 700;
                    }

                    img {
                        width: 70%;
                        height: fit-content;
                    }

                    .star {
                        font-size: 25px;
                        bottom: -17px;
                        left: -12px;
                        position: absolute;
                        color: rgba(255, 255, 255, 0.8);
                    }

                    .starred {
                        color: #ffce30;
                        text-shadow: 2px -1px 5px #000000b8;
                    }
                }

                .display {
                    display: flex;
                    width: 60%;
                    flex-direction: column;
                    justify-content: center;

                    .names {
                        display: flex;

                        .client-name {
                            font-weight: bold;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .rep-name {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .rep-name::before {
                            content: "|";
                            margin: 0 5px;
                        }
                    }

                    .preview {
                        width: 90%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin: 0;
                    }
                }

                .update {
                    width: 15%;
                    flex-direction: column;
                    display: flex;
                    align-items: center;

                    .count {
                        //height: 13px;
                        align-items: center;
                    }

                    .time {
                        font-size: 10px;
                    }
                }

                .update.new-msg {

                    .count {
                        margin: auto;
                        font-size: 12px;
                        background: #1abc9c;
                        height: 28px;
                        width: 28px;
                        color: white;
                        border-radius: 50%;
                        line-height: 28px;
                        display: block;
                        text-align: center;
                        font-weight: 700;
                    }

                    .time {
                        color: #1abc9c;
                    }
                }

                .action {
                    display: flex;
                    width: 10%;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .form-check-input {
                        width: 1.2em;
                        height: 1.2em;
                        border: 2px solid #4a81d4;
                        box-shadow: 0px 0px 2px 1px #4a81d4;
                        animation: wiggle 0.5s ease 0.5s 2 alternate;
                        animation: wiggle 2s linear 2;
                    }

                    /* Keyframes */
                    @keyframes wiggle {

                        0%,
                        7% {
                            transform: rotateZ(0);
                        }

                        15% {
                            transform: rotateZ(-15deg);
                        }

                        20% {
                            transform: rotateZ(10deg);
                        }

                        25% {
                            transform: rotateZ(-10deg);
                        }

                        30% {
                            transform: rotateZ(6deg);
                        }

                        35% {
                            transform: rotateZ(-4deg);
                        }

                        40%,
                        100% {
                            transform: rotateZ(0);
                        }
                    }

                    .form-check-input:checked {
                        background-color: #4a81d4;
                        border: 1px solid #4a81d4;
                    }

                    .select-action {

                        i {
                            font-size: 30px;
                            display: block;
                        }

                        .dropdown-menu {
                            opacity: 1;
                            z-index: 999999;
                        }
                    }
                }

                .chat-item-menu {
                    position: absolute;
                    top: 5px;
                    right: 0;
                    display: flex;
                    align-items: center;
                    margin-left: auto;
                }

                .chat-item-menu .dropdown-toggle {
                    background-color: transparent;
                    border: none;
                    padding: 0;
                }

                .chat-item-menu .dropdown-toggle::after {
                    display: none;
                }
            }

            .chat-item {
                align-items: center;

                .joined-mode-notification {
                    font-size: 1.2rem;
                }
            }

            .chat-item.transferred {
                background-color: #f2f2f2;

                .joined-mode-notification {
                    .join-icon-wrp {
                        display: flex;
                    }

                    color: #1abc9c;

                    i.mdi.mdi-flash {
                        color: #1abc9c69;
                        margin-left: -4px;
                        transform: rotate(16deg);
                        display: inline-block;
                    }
                }
            }

            .chat-item.follower {
                background-color: #e4ecf8;

                .joined-mode-notification {
                    font-size: 1.3rem;
                    color: #4a81d4;
                }
            }

            .chat-item.inactive {
                background-color: #fbecee;

                .joined-mode-notification {
                    font-size: 1.3rem;
                    color: #f1556c;
                }
            }

            .chat-item.agent-joined {
                background-color: #f2f2f2;

                .joined-mode-notification {
                    color: #75a8f5;
                }
            }

            .chat-item.item-selected {
                background-color: #40bb9c45;
                transform: scale(1, 1.01);
                transition: 0.2s ease;
                z-index: 9999;
                box-shadow: 0px 0px 11px 5px #8080803b;
            }

            .chat-item.system-user-inactive {
                align-items: center;

                .update,
                .display,
                .avatar {
                    opacity: 0.5;
                }

                .mdi-bell-off {
                    font-size: 2rem;
                }
            }

        }

        .chat-list.is-manager {
            // height: calc(95vh - calc(145px + 163px));
        }
    }

    .chat-user-wrapper {
        width: 69%;
        margin-left: 15px;
        display: flex;
        height: calc(98vh - 100px);
        overflow-y: clip;

        .no-chat-selected {
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            align-items: center;
            font-size: 1.7rem;
            opacity: 0.7;
        }

        .user-chat-area {
            width: 50%;
            border-radius: 0.25rem;
            box-shadow: 0 0.75rem 6rem #38414a08;

            .conversation-list .chat-update.danger {
                .conversation-text .ctext-wrap {
                    background-color: rgba(241, 85, 108, 0.8);
                    color: black;
                }
            }

            .conversation-list .chat-update {

                .conversation-text {
                    width: 70%;
                    float: unset;
                    margin: auto;

                    .ctext-wrap {
                        background-color: #deeeeb;
                        padding: 6px;
                        text-align: center;
                    }

                    .ctext-wrap:after {
                        display: none;
                    }
                }
            }

            .whatsapp-window {
                height: calc(98vh - calc(88px + 116px));
            }

            .whatsapp-window.includes-send-area {
                height: calc(98vh - calc(88px + 96px + 153px));
            }

            .card-body {
                flex: 1 1 auto;
                padding: .8rem 1.5rem;
            }

            .send-msg-box {

                // overflow-y: auto;
                // overflow-x: hidden;
                .dropdown-menu.show {
                    overflow: auto !important;
                    height: 300px !important;
                }
            }
        }

        .user-profile-area {
            width: 49%;
            margin-left: 15px;

            .user-profile-box {
                margin-bottom: 15px;
                height: calc(98vh - calc(117px + 35vh));
                overflow: auto;
            }

            .comments-box {
                margin-bottom: 15px;
                height: 35vh;
                // min-height: 325px;
                overflow: auto;

                // .comments-send-msg {
                //     position: absolute;
                //     bottom: 12px;
                //     width: 95%;
                // }

                // .card-body {
                //     padding: 1rem 1.5rem;

                //     .header-title {
                //         margin-bottom: 0.8rem !important;
                //     }

                //     .comments-msg-area.col {
                //         height: calc(35vh - 204px);
                //         overflow: auto;
                //     }
                // }
            }
        }
    }
}

.whatsapp-manager-wrapper ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.whatsapp-manager-wrapper ::-webkit-scrollbar-thumb {
    background: #1abc9c;
    border-radius: 20px;
}

.whatsapp-manager-wrapper ::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.whatsapp-manager-wrapper {
    .simplebar-scrollbar:before {
        background: #1abc9c;
        opacity: 1;
    }

    .simplebar-scrollbar.simplebar-visible:before {
        opacity: 1;
    }
}

*,
*::before,
*::after {
    box-sizing: border-box;
}