.chat-area {

  .replied-message {
    background-color: rgba($color: #000000, $alpha: 0.07);
    border-left: 3px solid #1abc9c;
    padding: 5px;
    border-radius: 3px;
    margin-top: 20px;
  }

  .chat-area-header {
    flex-grow: 0;
    padding-bottom: 0.25em !important;
    padding-top: 0.25em !important;
    border-color: #ccc;
    //background: #40bb9c45;
    background: #e0e0e0;

    .user-name {
      a {
        font-weight: bold;
        color: #333;
      }

      a:hover {
        color: #666;
      }
    }
  }

  .ctext-wrap.future {
    background-color: rgba(26, 188, 156, 0.2);
    position: relative;

    .actions {
      position: absolute;
      bottom: 10px;
      left: 5px;

      i {
        display: inline;
        margin-right: 5px;
        font-size: 15px;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 50% 15% 35%;

    .badge {
      margin-left: 5px;
      position: relative;
      top: -2px;
    }

    .user-name {
      grid-column: 1;
      grid-row: 1;
    }

    .followers {
      grid-column: 1;
      grid-row: 2;
    }

    .under-exp-msg {
      grid-column: 2;
      grid-row: 1/ span 3;
      color: red;
      font-weight: bold;
      font-size: 85%;
    }

    .assigned-bots {
      grid-column: 3;
      grid-row: 2;
      text-align: right;

      .cancel {
        color: #3283f6;
        margin-left: 5px;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .assign-bot {
      grid-column: 3;
      grid-row: 1;

      .select-action.dropdown {
        float: right;
        width: auto;
        margin-top: 0;
      }
    }

    .test-json-button {
      grid-column: 3;
      grid-row: 1;

      button {
        border: 0;
        background-color: white;
        padding: 0;
      }
    }

  }


  .rounded-circle {
    vertical-align: top;
    margin-top: 2px;
  }


  .rep {
    background: rgb(26, 188, 156);
    height: 42px;
    width: 42px;
    color: white;
    border-radius: 50%;
    line-height: 42px;
    display: block;
    font-weight: 700;
  }


  .select-action.dropdown {
    width: 210px;
    display: block;

    .card-drop {
      font-size: 14px;
      padding: 0;
      height: 30px;
    }


    .dropdown-item {
      border-bottom: 1px solid #ddd;

      .badge {
        width: 60px;
        margin-right: 10px;
      }

      .badge.bg-add {
        border: 1px solid #ccc;
        background-color: white;
        color: #aaa;
        font-weight: 500;
      }
    }
  }


  .form-wrapper {
    position: relative;

    textarea {
      direction: rtl;
      height: 100px;
      border: 1px solid #bbb;
      padding-right: 50px;
    }

    .mdi-attachment {
      //position: absolute;
      //top: 0px;
      //right: 20px;
      font-size: 30px;
      text-align: center;
    }

    .upload-attachment {
      position: absolute;
      top: 0;
      right: 10px;
      width: 50px;
      height: 50px;

      .dropzone {
        border: 0px;
        background: none;
        height: 100%;


        .dz-message {
          margin-top: 0px;
        }
      }


    }
  }

  .chat-inactive {
    background: #f0f0f0;
    width: 100%;
    height: 100%;
    line-height: 37px;
    font-size: 12px;
    color: #bbb;
    padding-left: 10px;
  }

  .btn-disabled {
    background: #ccc;
    color: #eee;
    cursor: auto;
  }

  .no-margin-right {
    padding-right: 0;
  }

  .box {
    height: 500px;
  }

  .zoom-interview-form {
    margin-top: 10px;
  }

  .zoom-interview-form a {
    color: #3283f6;
  }

  .conversation-list .ctext-wrap {
    display: block;
    direction: rtl;
    text-align: right;
    padding-left: 40px;
  }

  .chat-buttons .btn {
    margin-right: 5px;
    margin-top: 5px;
    background-color: #f1f3fa;
    color: #3283f6;
  }

  .message-action-menu {
    position: absolute;
    bottom: 10px;
    left: 5px;
    z-index: 1000;
  }

  .message-action-menu .dropdown-toggle {
    background-color: transparent;
    border: none;
    padding: 0;
  }

  .message-action-menu .dropdown-toggle::after {
    display: none;
  }


  .chat-update.danger .conversation-text .ctext-wrap {
    background-color: rgba(241, 85, 108, .8);
    color: #000;
  }

  .chat-update .conversation-text {
    width: 70%;
    float: unset;
    margin: auto;

    .ctext-wrap {

      background-color: #DEEEF5;
      padding: 6px;
      text-align: center;
    }

    .ctext-wrap:after {
      display: none;
    }
  }

  .assign-template {
    border: 1px solid #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 37px;
  }


  img.message-status {
    height: 15px;
    position: absolute;
    top: 12px;
    left: 13px;
  }

  .conversation-list {
    direction: rtl;

    .attachment-container {
      max-width: 320px;
      max-height: 320px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .ctext-wrap i.message-status {
      //height: 15px;
      position: absolute;
      top: 8px;
      left: 16px;
      font-size: 16px;
    }
  }

  li .conversation-text {
    max-width: 76%;
    width: auto;
  }

  .sender-name {
    font-weight: bold;
    padding-left: 30px;
  }

  @media (max-width: 1600px) {
    .message-drop-down {
      position: relative;

      .dropdown-menu {
        position: absolute;
        left: -120px !important;
      }
    }
  }

  .communication-method {
    position: absolute;
    top: -10px;
    width: 45%;
  }

  .right-side-icon {
    right: 0px;
  }

  .left-side-icon {
    left: 0px;
  }

  .status-circle {
    display: inline-block;
    position: absolute;
    top: 8px;
    left: 5px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }

  .dropdown-menu-inner {
    max-height: 500px;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

  }
}

.fixed-width {
  min-width: 310px;
}