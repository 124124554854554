.product-transfers {
  
  .resubmit-b2 {
    margin-top: 20px;
  }

  .total-onetime {
    background-color: #ddd;
    color: #000;
  }

  a.text-body {
    color: #6658dd !important;
  }

  .react-datepicker__aria-live {
    display: none;
  }

  .date-input {
    text-align: left;
    width: 120px;
  }

  .policy-input {
    width: 180px;
  }

  .left {
    float: left;
  }

  .client-name {
    cursor: pointer;
    color: #6658dd;
  }

  .upload-btn {
    margin-right: 20px;
  }

  .category-name {
    display: inline-block;
    margin-bottom: 0px;
    margin-top: 25px;
    color: #333;
  }

  .category-value {
    margin-top: 4px;
    margin-left: 0;
    color: #4d78cd;
    font-weight: bold;
  }

  .negative.btn-primary:disabled, .negative.btn-primary.disabled {
    color: #4d78cd;
    background-color: white;
    border-color: #4d78cd;
    margin-left: 10px;
  }

  .negative {
    color: #4d78cd;
    background-color: white;
    border-color: #4d78cd;
    margin-left: 10px;
  }

  .product-transfer-expanded {
    display: flex;
    padding-bottom: 30px;
    .data-div {
      flex: 1
    }

    .table-wrapper {
      //flex-basis: 30%;
      margin-top: 25px;
      padding-left: 0;
      margin-left: -20px;
      .commissions {
        th {
          padding-left: 0;
        }

        td {
          padding: 5px 40px 5px 0;
        }

        tr {
          border-bottom: 1px solid #eee;
        }
      }
    }
  }

  .rtl {
    direction: rtl;
    text-align: right;

    .dropdown-item {
      text-align: right;
    }

    .end {
      float: left;
    }

    .start {
      float: right;
    }

    input, textarea {
      text-align: right;
    }
  }

  .ltr {
    direction: ltr;
    text-align: left;

    .dropdown-item {
        text-align: left;
    }

    .end {
        float: right;
    }

    .start {
        float: left;
    }

    input, textarea {
      text-align: left;
    }
  }

  .file-upload-area {
    border: 2px dashed #ddd;
    padding: 0px;
    margin-top: 30px;
    margin-bottom: 10px;
    cursor: pointer;
    width: 350px !important;
    min-width: 350px !important;
    max-width: 350px !important;
    height: 100%;
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-direction: column;
    text-align: center;
  }
}